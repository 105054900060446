export interface AuthState {
  user: User | null;
  accessToken: string;
  refreshToken: string;
}

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  permissions: PermissionCode[];
  providers: string[];
  id: string;
}

export interface UserDTO {
  first_name: string;
  last_name: string;
  email: string;
  permissions: PermissionCode[];
  providers: string[];
  id: string;
}

export interface AuthLoginDTO {
  token_type: 'bearer';
  access_token: string;
  expires_in: number;
  refresh_token: string;
  user: UserDTO;
}

export enum PermissionCode {
  UsersList = 'users:list',
  UsersDetails = 'users:details',
  UsersEdit = 'users:edit',
  UsersEditRoles = 'users:edit:roles',
  UsersEditProviders = 'users:edit:providers',
  UsersRemove = 'users:remove',
  UsersSearch = 'users:search',
  AccommodationsList = 'accommodations:list',
  AccommodationsDetails = 'accommodations:details',
  AccommodationsEdit = 'accommodations:edit',
  AccommodationsSearch = 'accommodations:search',
  CompaniesList = 'providers:list',
  CompaniesDetails = 'providers:details',
  CompaniesEdit = 'providers:edit',
  CompaniesSearch = 'providers:search',
  RolesList = 'roles:list',
  RolesDetails = 'roles:details',
  RolesEdit = 'roles:edit',
  RolesSearch = 'roles:search',
  ReportsList = 'reports:list',
  PermissionsView = 'permissions:view',
}

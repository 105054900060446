import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '../environments/environment';
import { authGuard, authGuardChild } from './core/guards/auth-guard.guard';
import { roleGuard } from './core/guards/role-guard.guard';
import { PermissionCode } from './core/models/auth.model';
import { authInterceptor } from './core/services/auth.interceptor';

export const HttpLoaderFactory = (http: HttpBackend) => new TranslateHttpLoader(new HttpClient(http));

const routes: Routes = [
  {
    path: 'auth/login',
    canActivate: [authGuard],
    loadComponent: () => import('./authentication/login/login.component').then(c => c.LoginComponent),
  },
  {
    path: 'auth/reset-password',
    canActivate: [authGuard],
    loadComponent: () => import('./authentication/reset-password/reset-password.component').then(c => c.ResetPasswordComponent),
  },
  {
    path: 'auth/create-password',
    canActivate: [authGuard],
    loadComponent: () => import('./authentication/create-password/create-password.component').then(c => c.CreatePasswordComponent),
  },
  {
    path: '',
    canActivateChild: [authGuardChild],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
      },
      {
        path: 'bookings',
        canActivate: [roleGuard],
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
        data: {
          permissions: [PermissionCode.PermissionsView],
        },
      },
      {
        path: 'acquisition/companies',
        canActivate: [roleGuard],
        loadComponent: () => import('./acquisition/companies/companies.component').then(c => c.CompaniesComponent),
        data: {
          permissions: [PermissionCode.CompaniesList],
        },
      },
      {
        path: 'acquisition/accommodations',
        canActivate: [roleGuard],
        loadComponent: () => import('./acquisition/accommodations/accommodations.component').then(c => c.AccommodationsComponent),
        data: {
          permissions: [PermissionCode.AccommodationsList],
        },
      },
      {
        path: 'acquisition/accommodations/:id',
        canActivate: [roleGuard],
        loadComponent: () =>
          import('./acquisition/accommodations/components/accommodation-details/accommodation-details.component').then(
            c => c.AccommodationDetailsComponent,
          ),
        data: {
          permissions: [PermissionCode.AccommodationsDetails],
        },
      },
      {
        path: 'acquisition/settings',
        canActivate: [roleGuard],
        loadComponent: () => import('./acquisition/settings/settings.component').then(c => c.SettingsComponent),
        data: {
          permissions: [PermissionCode.PermissionsView],
        },
      },
      {
        path: 'reports',
        canActivate: [roleGuard],
        loadComponent: () => import('./reports/reports.component').then(c => c.ReportsComponent),
        data: {
          permissions: [PermissionCode.ReportsList],
        },
      },
      {
        path: 'share',
        canActivate: [roleGuard],
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
        data: {
          permissions: [PermissionCode.PermissionsView],
        },
      },
      {
        path: 'content',
        canActivate: [roleGuard],
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
        data: {
          permissions: [PermissionCode.PermissionsView],
        },
      },
      {
        path: 'management',
        canActivate: [roleGuard],
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
        data: {
          permissions: [PermissionCode.PermissionsView],
        },
      },
      {
        path: 'marketing',
        canActivate: [roleGuard],
        loadComponent: () => import('./home/home.component').then(c => c.HomeComponent),
        data: {
          permissions: [PermissionCode.PermissionsView],
        },
      },
      {
        path: 'users',
        canActivate: [roleGuard],
        loadComponent: () => import('./users/users.component').then(c => c.UsersComponent),
        data: {
          permissions: [PermissionCode.UsersList],
        },
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
      NgxMapboxGLModule.withConfig({
        accessToken: environment.mapbox.accessToken,
      }),
    ),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(routes, withComponentInputBinding()),
  ],
};
